import Link from 'next/link';

export const GiftCardBanner = () => (
  <div className="gift-card-hp">
    <div className="image-container">
      <img
        src="https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/grassdoor.com/web_static_files/Gift_Cards.png"
        className="img-fluid w-100"
        alt="seo-tag"
        loading="lazy"
        height="160"
        width="350"
      />
    </div>
    <div className="card-details py-4 pb-5">
      <h2 className="bold">
        Send your friends a <br />
        $25 Gift Card - on us!
      </h2>
      <div class="earn-text">
        <span className="credit">
          Earn up to <strong> $600</strong> for every friend
        </span>
      </div>
      <Link href="/referral">
        <button className="btn btn-primary mt-3 mb-2">Tell a friend</button>
      </Link>
      <p className="text-center fs-12 mb-0">New Customers 21+</p>
    </div>
  </div>
);
