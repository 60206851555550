import React, { useEffect, useRef, useState } from 'react'

export default function SeeMore({ text, classname = "" }) {
  const [seeMore, setSeeMore] = useState(true)
  const [overAllHeight, setOverAllHeight] = useState(false)
  let seeMoreRef = useRef(null)
  useEffect(() => {
    if (seeMoreRef.current.offsetHeight > 38) {
      setSeeMore(true)
      setOverAllHeight(true)
    } else {
      setSeeMore(false)
    }
  }, [seeMoreRef.current])
  return (
    <h6 ref={seeMoreRef} className={`${classname} ${seeMore ? "restrict-two" : ""} see-more-text`}>
      {text}
      <span className={overAllHeight && seeMore ? "" : "d-none"} onClick={() => setSeeMore(!seeMore)}>{seeMore ? "..." : ""}<u>See {seeMore ? "more" : "less"}</u></span>
    </h6>
  )
}
