import React, { useEffect, useState, useRef, useMemo, memo } from 'react';
import Link from 'next/link';
import Slider from '../../CustomSlider';
import Product from '../../Product';
import NoProductAvailable from '../../no-products-available';
import { shopFullMenu } from '../../../constants/segmentSource';
import { ASAP } from '../../../constants/deliveryType';
import GrassdoorSecondaryBanners from './GrassdoorSecondaryBanners';
import ReferralAndFnFBannerData from '../../../constants/ReferralAndFnFBannerData';
import * as WEBSITES from '../../../constants/website';
import isMobile from '../../../helpers/isMobile';
import SeeMore from '../../SeeMore';
import BrandMenu from '../BrandMenu';
import { isNewHomePageApplicable } from '../../../constants/feature';
import SpecialCategoryLayout from './SpecialCategoryLayout';
import { useRouter } from 'next/router';
import { GiftCardBanner } from '../../GiftCartBanner';
import { useDeliveryDetails } from '../../../hooks/app';
import { useDispatch, useSelector } from 'react-redux';
import { setPositionID } from '../../../redux/slices/otherSlice';

const productSliderDesktop = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 3,
  arrows: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true
      }
    }
  ]
};

const CategoriesSkeleton = memo(({
  categories = [],
  currentTab,
  fetching,
  setTrack,
  setTab,
  applyFilters,
  asapProductsCount,
  scheduledProductsCount,
  showViewAll = true,
  secondaryBannerData,
  tertiaryBannerData,
  initialLocationAddress = false,
  initialFreightLimit = false,
  initialPurchaseLimit = false,
  initialUpsellProducts = false,
  initialDeliveryAddressDetails = false,
  topBrands,
  showSpecialCategory = false,
}) => {
  const { data: { unified_menu, asapStartTime, asapEndTime, lastSlotCutOff, scheduleEnabled, asapEnabled } = {} } = useDeliveryDetails();
  const [categoriesList, setCategoriesList] = useState([]);
  const [specialCategoriesList, setSpecialCategoriesList] = useState([]);
  let bannerIndexes = {
    secondaryBanner: 0,
    referralAndFnFBanner: 3,
    newBanner: 5,
    topBrandsCarousel: 7,
    newBannerTwo: 9,
  };

  const updateBannerIndex = index => {
    if (index === null || index < 0) return null;
    const indices = Object.values(bannerIndexes).sort((a, b) => a - b);
    if (!isNewHomePageApplicable) {
      index -= indices.slice(0, indices.findIndex(i => i === index)).length;
    }
    return index !== null ? (categories?.length >= index + 1 ? index : categories?.length - 1) : 0;
  };

  bannerIndexes = {
    ...bannerIndexes,
    secondaryBanner: updateBannerIndex(bannerIndexes.secondaryBanner),
    referralAndFnFBanner: updateBannerIndex(bannerIndexes.referralAndFnFBanner),
    newBanner: updateBannerIndex(bannerIndexes.newBanner),
    topBrandsCarousel: updateBannerIndex(bannerIndexes.topBrandsCarousel),
    newBannerTwo: updateBannerIndex(bannerIndexes.newBannerTwo),
  };

  if (secondaryBannerData?.length <= 0) {
    bannerIndexes = {
      ...bannerIndexes,
      secondaryBanner: null,
      referralAndFnFBanner: bannerIndexes.referralAndFnFBanner - bannerIndexes.secondaryBanner - 1,
      newBanner: bannerIndexes.newBanner - bannerIndexes.secondaryBanner - 1,
      topBrandsCarousel: bannerIndexes.topBrandsCarousel - bannerIndexes.secondaryBanner - 1,
      newBannerTwo: bannerIndexes.newBannerTwo - bannerIndexes.secondaryBanner - 1,
    };
  }

  const categoriesLength = useRef(0);

  const handleCategories = useMemo(() => {
    if (!showSpecialCategory) {
      setCategoriesList(categories);
    } else {
      if (!categories) return;
      let specialCatgoriesTemp = [];
      let slugList = [];
      let tempList = categories?.filter(item => {
        if (["buy-again", "daily-deals"].includes(item.category_slug) || item.category_name === "Top Sellers This Week") {
          if (!slugList.includes(item.category_slug)) {
            specialCatgoriesTemp.push({ ...item, ...categoryData[item.category_name === "Top Sellers This Week" ? "top-sellers-this-week" : item.category_slug] });
            slugList.push(item.category_slug);
          }
        }
        return !(["buy-again", "daily-deals"].includes(item.category_slug) || item.category_name === "Top Sellers This Week");
      });
      setSpecialCategoriesList([...specialCatgoriesTemp]);
      setCategoriesList([...tempList]);
    }
  }, [categories]);

  useEffect(() => {
    categoriesLength.current = categories?.length;
    if (categories?.length === categoriesLength?.current) return;
    handleCategories();
  }, [categories]);

  if (categoriesList && categoriesList?.length) {
    return (
      <>
        {isNewHomePageApplicable ? (
          specialCategoriesList.map((data, count) => (
            <SpecialCategoryLayout
              key={data.category_slug}
              categoryName={data.category_name}
              className={data.class}
              extraDetails={data.extraDetails}
              slug={data.category_slug}
              currentTab={currentTab}
              viewAll={data.category_view_all}
              productSize={data.products.length}
              rightSide={!!(count % 2)}
              titleSection={data.titleSection}
              isMostPopular={data.isMostPopular}
              desc={data.desc}
              toShop={data.category_slug === 'daily-deals'}
            >
              {data.products.map((item, categoryIndex) => (
                <Product
                  key={`product_list_${item.product_id || item.bundle_id}`}
                  page={currentTab === ASAP ? 'ASAP List' : 'Schedule List'}
                  productIndex={categoryIndex}
                  categoryIndex={categoryIndex}
                  data={item}
                  inShop
                  categorySlug={item.category_slug || categorySlug}
                  source={shopFullMenu}
                  categoryID={item.id || item.category_id}
                  openInPopup
                  initialLocationAddress={initialLocationAddress}
                  initialFreightLimit={initialFreightLimit}
                  initialPurchaseLimit={initialPurchaseLimit}
                  initialUpsellProducts={initialUpsellProducts}
                  initialDeliveryAddressDetails={initialDeliveryAddressDetails}
                  unified_menu={unified_menu}
                  asapStartTime={asapStartTime}
                  asapEndTime={asapEndTime}
                  lastSlotCutOff={lastSlotCutOff}
                  asapEnabled={asapEnabled}
                  scheduleEnabled={scheduleEnabled}
                />
              ))}
            </SpecialCategoryLayout>
          ))
        ) : null}
        {categoriesList.map((data, categoryIndex) => (
          <React.Fragment key={`cat_${data.id || data.category_id}`}>
            <IndividualCategory
              data={data}
              currentTab={currentTab}
              categoryIndex={categoryIndex}
              showViewAll={showViewAll}
              setTrack={setTrack}
              initialLocationAddress={initialLocationAddress}
              initialFreightLimit={initialFreightLimit}
              initialPurchaseLimit={initialPurchaseLimit}
              initialUpsellProducts={initialUpsellProducts}
              initialDeliveryAddressDetails={initialDeliveryAddressDetails}
            />
            {categoryIndex === bannerIndexes.secondaryBanner && process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && (
              <GrassdoorSecondaryBanners secondaryBannerData={secondaryBannerData} />
            )}
            {categoryIndex === bannerIndexes.referralAndFnFBanner && process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && (
              <GiftCardBanner />
            )}
            {categoryIndex === bannerIndexes.newBanner && process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && (
              <GrassdoorSecondaryBanners secondaryBannerData={ReferralAndFnFBannerData} tertiaryBannerData={tertiaryBannerData} newBanner firstBanner={isMobile()} />
            )}
            {categoryIndex === bannerIndexes.topBrandsCarousel && !isNewHomePageApplicable && (
              <BrandMenu topBrands={topBrands} />
            )}
            {categoryIndex === bannerIndexes.newBannerTwo && process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && isMobile() && (
              <GrassdoorSecondaryBanners secondaryBannerData={ReferralAndFnFBannerData[1]} newBanner secondBanner={isMobile()} />
            )}
          </React.Fragment>
        ))}
      </>
    );
  }

  if (!fetching) {
    return (
      <div className="one-rem-mt four-rem-mb">
        <NoProductAvailable
          asapProductsCount={asapProductsCount}
          scheduledProductsCount={scheduledProductsCount}
          currentTab={currentTab}
          setTab={setTab}
          applyFilters={applyFilters}
          asapEnabled={asapEnabled}
          scheduleEnabled={scheduleEnabled}
          setTrack={setTrack}
        />
        <GrassdoorSecondaryBanners secondaryBannerData={secondaryBannerData} />
        {process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && (
          isNewHomePageApplicable ? (
            <GiftCardBanner />
          ) : (
            <GrassdoorSecondaryBanners secondaryBannerData={ReferralAndFnFBannerData} />
          )
        )}
      </div>
    );
  }

  return null;
});

const ViewAllLink = ({ slug, tab, categoryID, link }) => {
  let url = link && link !== '' ? { pathname: link, query: { tab } } : { pathname: `/shop/${slug}`, query: { tab } };
  if (link && (link.indexOf('tab=schedule') !== -1 || link.indexOf('tab=asap') !== -1)) {
    url = link;
  }
  const dispatchRedux = useDispatch();
  const saveCategoryPosition = () => {
    dispatchRedux(setPositionID({ position: categoryID }));
  };

  return (
    <div className="col-3 d-flex justify-content-end">
      <Link prefetch={false} href={url}>
        <a onClick={saveCategoryPosition} className="view-all">
          View All
        </a>
      </Link>
    </div>
  );
};

const IndividualCategory = ({
  data,
  currentTab,
  categoryIndex,
  showViewAll,
  setTrack,
  initialLocationAddress,
  initialFreightLimit,
  initialPurchaseLimit,
  initialUpsellProducts,
  initialDeliveryAddressDetails,
  from,
  fromPage,
  showBentoTopBrands,
  unified_menu,
  asapStartTime,
  asapEndTime,
  lastSlotCutOff,
  asapEnabled,
  scheduleEnabled,
}) => {
  let link = data.category_view_all;
  if (link && link !== '' && link.toLowerCase().indexOf('http://') === -1 && link.toLowerCase().indexOf('https://') === -1) {
    link = `https://${link}`;
  }
  const [showCategory, setShowCategory] = useState(categoryIndex === 0);
  const categoryRef = useRef();
  const positionID = useSelector(state => state.other.positionID);
  const dispatchRedux = useDispatch();
  const router = useRouter();

  useEffect(() => {
    const currentScrollPosition = `cat_${data.id}`;
    if (positionID && positionID === currentScrollPosition) {
      const elem = document.getElementById(positionID);
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
      dispatchRedux(setPositionID({ position: null }));
    }
  }, [positionID, data.id, dispatchRedux]);

  useEffect(() => {
    const currentRef = categoryRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowCategory(true);
        }
      },
      {
        root: null,
        rootMargin: '500px',
        threshold: 0.1
      }
    );
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      observer.unobserve(currentRef);
    };
  }, [data]);

  const topSellersViewAll = useMemo(() => (
    data.category_name === "Top Sellers This Week" && router.pathname !== "/top-sellers-hub"
  ), [data, router]);

  if (topSellersViewAll) link = "/top-sellers-hub";

  const viewAll = topSellersViewAll || (showViewAll && !(data.category_name === 'Most Popular' || data.isMostPopular || data.category_name === 'Recommended Products'));

  return (
    <div
      ref={categoryRef}
      id={`cat_${data.id || data.category_id}`}
      key={`cat_${data.id || data.category_id}`}
      className="product-category lazy-load-category-height"
    >
      {showCategory && (
        <>
          <div className="row">
            <div className="col">
              <h3 className="h4 a-category-name bold">{data.category_name}</h3>
            </div>
            {viewAll && (
              <ViewAllLink
                categoryID={`cat_${data.id || data.category_id}`}
                slug={data.category_slug}
                tab={currentTab}
                link={link}
              />
            )}
          </div>
          {isNewHomePageApplicable ? (
            <SeeMore text={data.category_description} className="description mb-0 mt-2" />
          ) : (
            <h6 className="description mb-0 mt-2">{data.category_description}</h6>
          )}
          {data.category_offer_description && (
            <em>
              <div className="mini-description mt-1 mb-3">{data.category_offer_description}</div>
            </em>
          )}
          {data.category_sale_description && (
            <em>
              <div className="mini-description mt-1 mb-3">{data.category_sale_description}</div>
            </em>
          )}
          <div className="all-products-slider relative row m-0">
            <Slider {...productSliderDesktop}>
              {data.products.map((item, productIndex) => (
                <Product
                  key={`product_list_${item.product_id || item.bundle_id}`}
                  page={currentTab === ASAP ? 'ASAP List' : 'Schedule List'}
                  productIndex={productIndex}
                  categoryIndex={categoryIndex}
                  data={item}
                  inShop
                  categorySlug={item.category_slug || data.categorySlug}
                  source={shopFullMenu}
                  categoryID={data.id || data.category_id}
                  openInPopup
                  isMostPopularProduct={data.isMostPopular}
                  title={data.category_name}
                  initialLocationAddress={initialLocationAddress}
                  initialFreightLimit={initialFreightLimit}
                  initialPurchaseLimit={initialPurchaseLimit}
                  initialUpsellProducts={initialUpsellProducts}
                  initialDeliveryAddressDetails={initialDeliveryAddressDetails}
                  from={from}
                  fromPageType={fromPage}
                  unified_menu={unified_menu}
                  asapStartTime={asapStartTime}
                  asapEndTime={asapEndTime}
                  lastSlotCutOff={lastSlotCutOff}
                  asapEnabled={asapEnabled}
                  scheduleEnabled={scheduleEnabled}
                />
              ))}
            </Slider>
          </div>
        </>
      )}
    </div>
  );
};

export default CategoriesSkeleton;

const categoryData = {
  "buy-again": {
    class: "bring-it-again",
    extraDetails: { titleText: "Ready for more?", buttonText: "View Similar Products" },
    titleSection: 'Bring It Again',
    desc: 'Re-order your favorites!'
  },
  "daily-deals": {
    class: "deal-of-the-day",
    extraDetails: { titleText: "Ready for more?", buttonText: "View Similar Products" },
    titleSection: 'Deals of the Day',
    desc: 'Unbeatable deals while supplies last!'
  },
  "top-sellers-this-week": {
    class: "recommended",
    extraDetails: { titleText: "Ready for more?", buttonText: "View Similar Products" },
    titleSection: 'Recommended for You',
    desc: 'We know you will enjoy there products'
  },
};
