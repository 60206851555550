import React from 'react';
import dynamic from 'next/dynamic';

import { BENTO, GRASSDOOR } from '../../constants/website';

const GrassdoorCategoryMenu = dynamic(() => import('./grassdoor/BrandMenu'));
const CommonBrandMenu = props => {
  switch (process.env.NEXT_PUBLIC_APP) {
    case GRASSDOOR:
    case BENTO:
      return <GrassdoorCategoryMenu {...props} />;
    default:
      return null;
  }
};

export default CommonBrandMenu;
